import React from 'react'
import './css/things.css'

function Things() {
  return (
    <div>
 
    <div className="sn-spacer m30 d30"></div>
    <div className="sn-spacer m30 d30"></div>
    
    <div className="utils_container">
    
        <div className="bts_01-icon_list bts_01-icon_list-separation">
          
         
          <div className="bts_01-icon_list-icon_item">
            <div className="bts_01-icon_list-icon_wrapper">
              <img className="bts_01-icon_list-icon" src="./images/icon-ear-navy.jpg" alt='' />
            </div>
            <h4 className="bts_01-title">Hearing Test</h4>
            <div className="bts_01-copy"></div>
          </div>
          
          <div className="sn-spacer m30 d30"></div>
          
          <div className="bts_01-icon_list-icon_item">
            <div className="bts_01-icon_list-icon_wrapper">
              <img className="bts_01-icon_list-icon" src="images/login-icon-small.jpg" alt='' />
            </div>
            <h4 className="bts_01-title">Expert Audiologists</h4>
            <div className="bts_01-copy"></div>
          </div>
        
         </div>
        </div>
        <div className="sn-spacer m30 d30"></div>
        <div className="sn-spacer m30 d30"></div>
        <div className="utils_container">
            
        <div className="bts_01-icon_list bts_01-icon_list-separation">
          
         
          <div className="bts_01-icon_list-icon_item">
            <div className="bts_01-icon_list-icon_wrapper">
              <img className="bts_01-icon_list-icon" src="images/shield.png" alt='' />
            </div>
            <h4 className="bts_01-title">No Obligation to Buy</h4>
            <div className="bts_01-copy"></div>
          </div>
          
          <div className="sn-spacer m30 d30"></div>
          
          <div className="bts_01-icon_list-icon_item">
            <div className="bts_01-icon_list-icon_wrapper">
              <img className="bts_01-icon_list-icon" src="images/battery.png" alt='' />
            </div>
            <h4 className="bts_01-title">2+ Year Warranty</h4>
            <div className="bts_01-copy"></div>
          </div>
         </div>
        </div>
        <div className="sn-spacer m30 d30"></div>
        <div className="sn-spacer m30 d30"></div>

    </div>
  )
}

export default Things;
