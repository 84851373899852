import React, {useEffect, useState} from "react";
import axios from 'axios';
import '../App.css';

const List = ()=>{
    const [Data, setData] = useState([]); 

    window.onbeforeunload = (ev) => {  
        ev.preventDefault(localStorage.removeItem('previlages'));
    };
    
    const GetListData = () =>{
    const url = 'https://sr-backend-0e5a.onrender.com/api/patients'
    axios.get(url)
    .then(response=>{
        const result = response.data;
        const {status, message, data} = result;
        if(status !== 'SUCCESS'){
            alert(message,status)
        }
        else{
            setData(data)
        }
    })
    .catch(err => {
        console.log(err);
    })
    }

    //  handle delete function
    const deletePatient = (_id) =>{
        const url = `https://sr-backend-0e5a.onrender.com/api/patients/${_id}`
    axios.delete(url)
    .then(response=>{
        const result = response.data;
        const {status, message} = result;
        if(status !== 'success'){
            alert(message, status)
        }
        else{
            setData(message)
            window.location.reload()
        }
    })
    .catch(err => {
        console.log(err);
    })
    }

    useEffect(()=>{
        GetListData();
    },[])


    if(localStorage.getItem('previlages') === "$2a$12$BoaltMfDw6d8x.U.nIx.sOzMdNm1drckjcBSVW/lnUj3uNnCInr6y"){
        return(
            <div>
                <div className="row">
                    <table className="customers">
                        <thead>
                            <tr>
                                <th><h1>First name</h1></th>
                                <th><h1>Last name</h1></th>
                                <th><h1>Email</h1></th>
                                <th><h1>Number</h1></th>
                                <th><h1>Date</h1></th>
                                <th><h1>Action</h1></th>
                            </tr>
                        </thead>
                        <tbody>
                            {Data.map((item)=>
                                <tr key={(item._id)}>
                                    <td><h2>{item.fname}</h2></td>
                                    <td><h2>{item.lname}</h2></td>
                                    <td><h2>{item.email}</h2></td>
                                    <td><h2>{item.number}</h2></td>
                                    <td><h2>{item.date}</h2></td>
                                    <td>
                                        <button onClick={()=>deletePatient(item._id)}>Delete</button>
                                    </td>
    
                                </tr>
                        )}
                        </tbody>
    
                    </table>
                </div>
            </div>
        )
    }
}

export default List;