import React from 'react'
import './css/about.css'

function About() {
  return (
    <div>

    <section className="about" id="about">

        <h1 className="heading"> about us </h1>

        <div className="row">

            <div className="image">
                <img src="images/about.png" alt="" />
            </div>

            <div className="content">
                <h3>Redefining Hearing Healthcare</h3>
                <p>At our hearing care clinic, we are committed to providing compassionate, personalized care to every patient who walks through our doors.</p>
                <p>We believe that better hearing health leads to a better quality of life. That's why we are passionate about helping our patients achieve optimal hearing health.</p>
                <a href="/About_us" className="btn">read more</a>
            </div>

        </div>

    </section>

    </div>
  )
}

export default About;
