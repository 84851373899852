import React from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import '../App.css';

function AboutUs() {
  return (
    <div>
        <Header />
        
        <div className='blogPage'>
                <h1>ABOUT US</h1><br /><br />
                <p className='paras'>Redefining Hearing Healthcare</p><br /><br />

            <div className='blog_img1'>
                <img src="images\audiometric.jpg" alt=''/><br /><br />
            </div>
            <div className='blog1'>
                <p className='para'> Our hearing care clinic is a specialized facility that focuses on the evaluation, diagnosis, and treatment of hearing loss and related conditions. Our clinics are staffed by professionals who are trained in audiology or hearing healthcare.</p><br /><br />
            </div>

            <div>
                <p className='parara'><b>Services:</b> In our Hearing care clinics offer a wide range of services to address various hearing needs. These may include hearing assessments, hearing aid fittings, counseling on hearing loss management, tinnitus management, earwax removal, and custom ear protection.</p><br /><br />
            </div>

            <div>
                <p className='parara'><b>Audiologists:</b> Audiologists are the primary professionals you will find at hearing care clinics. They are trained to assess and manage hearing and balance disorders. Audiologists perform comprehensive hearing evaluations, recommend appropriate treatment options, and prescribe and fit hearing aids if necessary.</p><br /><br />
            </div>

            <div>
                <p className='parara'><b>Hearing Aid Fittings:</b> If you require hearing aids, a hearing care clinic will guide you through the process. They will assess your hearing needs, recommend suitable hearing aids based on your lifestyle and hearing profile, and ensure proper fitting and programming of the devices.</p><br /><br />
            </div>

            <div>
                <p className='parara'><b>Tinnitus Management:</b> Tinnitus refers to the perception of sound in the ears or head without an external source. Hearing care clinics often offer specialized tinnitus management programs to help individuals cope with this condition. These programs may include counseling, sound therapy, and other techniques to reduce the impact of tinnitus on daily life.</p><br /><br />
            </div>

            <div>
                <p className='parara'><b>Follow-up Care:</b> Our Hearing care clinics provide ongoing support and follow-up care to ensure optimal hearing health. This includes periodic hearing evaluations, adjustments to hearing aids, and assistance with troubleshooting issues or concerns related to hearing devices.</p><br /><br />
            </div>

            <div>
                <p className='parara'><b>Education and Resources:</b> Our Hearing care clinics also offer educational resources and information on hearing health, hearing loss prevention, and communication strategies. And also conduct workshops, seminars, or support groups to help individuals and their families better understand and manage hearing-related challenges.</p><br /><br />
            </div>

        </div>

        <Footer />
    </div>
  );
}

export default AboutUs;
