import React, { useState} from "react";
import '../App.css';
import { useNavigate  } from "react-router-dom";
function Login() {

  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const logUser = async (e) => {
    e.preventDefault();

    const res = await fetch('https://sr-backend-0e5a.onrender.com/api/patients/login',{
      method : "POST",
      headers : {
        "Content-Type" : "application/json"
      },
      body:JSON.stringify({
        username,
        password
      })
    });

    const data = res.json();

    if(data === "failed"){
      window.alert("failed");
    }else {
      window.alert("Login Successfull");
      localStorage.setItem("previlages", "$2a$12$BoaltMfDw6d8x.U.nIx.sOzMdNm1drckjcBSVW/lnUj3uNnCInr6y")
      navigate("/appointmentlist")
    }
  }

  return (
    <div>
        
      <section className="login" id="login">
      <div className="log">
      <h1>Login</h1><br /><br /><br />

    <form method="POST" onSubmit={Login}>

        <input 
        value={username}
        onChange={(e)=> setUsername(e.target.value)}
        type="text" 
        placeholder="Username" 
        required="required" 
        />
        <br /><br /><br />


        <input 
        value={password}
        onChange={(e)=> setPassword(e.target.value)}
        type="password" 
        placeholder="Password" 
        required="required" 
        />
        <br /><br /><br />


        <button 
        type="submit" 
        onClick={logUser}
        className="btn btn-primary btn-block btn-large"
        >Let me in</button>


    </form>

      </div>
      </section>

    </div>
  )
}

export default Login;