import React from 'react'
import './css/blog.css'


function Blog() {
  return (
    <div>

    <section className="blog" id="blog">

        <h1 className="heading">our blogs</h1>

        <div className="box-container">

            <div className="box">
                <div className="image">
                    <img src="images/ghl.jpg" alt="" />
                </div>
                <div className="content">
                    <br/><br/>
                    <div className="icons">
                        <a href="/Genetic_Hearing_Loss"> <i className="fas fa-calendar"></i> 10th may, 2023 </a>
                        <a href="/Genetic_Hearing_Loss"> <i className="fas fa-user"></i> by admin</a>
                    </div>
                    <h3>Genetic Hearing Loss</h3>
                    <p>Genetic hearing loss is caused by mutations in one or more genes that are responsible for the development and function of the inner ear.</p>
                    <a href="/Genetic_Hearing_Loss" className="btn">read more</a>
                    <br/><br/><br/>
                </div>
            </div>

            <div className="box">
                <div className="image">
                    <img src="images/nihl.jpg" alt="" />
                </div>
                <div className="content">
                    <br/><br/>
                    <div className="icons">
                        <a href="/Noise-Induced_Hearing_Loss"> <i className="fas fa-calendar"></i> 12th may, 2023 </a>
                        <a href="/Noise-Induced_Hearing_Loss"> <i className="fas fa-user"></i> by admin</a>
                    </div>
                    <h3>Noise-Induced Hearing Loss</h3>
                    <p>NIHL is caused by exposure to loud noise, either at a single, high-intensity event or over a prolonged period of time.</p>
                    <a href="/Noise-Induced_Hearing_Loss" className="btn">read more</a>
                    <br/><br/><br/>
                </div>
            </div>

            <div className="box">
                <div className="image">
                    <img src="images/arhl.jpg" alt="" />
                </div>
                <div className="content">
                    <br/><br/>
                    <div className="icons">
                        <a href="/Age-Related_Hearing_Loss"> <i className="fas fa-calendar"></i> 20th may, 2023 </a>
                        <a href="/Age-Related_Hearing_Loss"> <i className="fas fa-user"></i> by admin</a>
                    </div>
                    <h3>Age-Related Hearing Loss</h3>
                    <p>Age-related hearing loss is caused by several factors that affect the delicate structures of the inner ear.</p>
                    <a href="/Age-Related_Hearing_Loss" className="btn">read more</a>
                    <br/><br/><br/>
                </div>
            </div>  
        </div>
    </section>

    </div>
  )
}

export default Blog;