import React from 'react'
import './css/services.css'

function Services() {
  return (
    <div>

        <section className="services" id="services">

        <h1 className="heading"> our services</h1>

        <div className="box-container">

            <div className="box">
                <img src="images/audiometric.jpg" alt="" />
                <h3>industry standard <br/> testing environment</h3>
                <p>Our hearing care clinic utilizes an industry standard testing environment to provide accurate and reliable hearing assessments.</p>
            </div>

            <div className="box">
                <img src="images/Hearing-aids.png" alt="" /><br/><br/>
                <h3>hearing aids</h3><br/>
                <p>Our experienced audiologists work closely with patients to select and customize the right hearing aid for their hearing loss and lifestyle.</p>
            </div>

            <div className="box">
                <img src="images/HearingExam.jpg" alt="" /><br/><br/>
                <h3>seamless services</h3><br/>
                <p>From hearing assessments to hearing aid fittings and ongoing support, we strive to make the journey to better hearing as smooth as possible.</p>
            </div>

{/*             <div className="box">
                <img src="images/online.jpg" alt="" /><br/><br/>
                <h3>Online Schedule</h3><br/>
                <p>With our online schedule, you can select your preferred time and date for your hearing assessment or hearing aid fitting with just a few clicks.</p>
            </div>
 */}
        </div>
    </section>


    </div>
  )
}

export default Services;
