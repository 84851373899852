import React from 'react'
import './css/product.css'

function Products() {
  return (
    <div>

    <section className="pricing" id="Product">
        <h1 className="heading">Product features</h1>
        <div className="box-container">
            <div className="box">
                <img src="images/itc.jpg" alt="" />
                <h3 className="title">In-the-canal <br/> hearing aid</h3>
            </div>
            <div className="box">
                <img src="images/ite.jpg" alt="" />
                <h3 className="title">In-the-ear <br/> hearing aids</h3> 
            </div>
            <div className="box">
                <img src="images/iic.jpg" alt="" />
                <h3 className="title">Invisible-in-Canal <br/> hearing aid</h3>
            </div>
            <div className="box">
                <img src="images/cic.jpg" alt="" />
                <h3 className="title">Completely-in-the-canal hearing aid </h3>
            </div>
            <div className="box">
                <img src="images/ric.jpg" alt="" />
                <h3 className="title">Receiver-in-the-canal <br/> hearing aids</h3>
            </div>
            <div className="box">
                <img src="images/bte.jpg" alt="" />
                <h3 className="title">Behind-the-ear <br/> Hearing aids</h3>
            </div>
        </div>
    </section>

    </div>
  )
}

export default Products;