import React from 'react';
import Appointment from '../components/appointment';
import Header from '../components/header';
import Main from '../components/main';
import Things from '../components/things';
import Services from '../components/services';
import Products from '../components/product';
import Aboutus from '../components/about';
import Blog from '../components/blogs';
import Footer from '../components/footer';
import '../App.css';

function Home() {
  return (
    <div>
        <Header />
        <Main />
        <Things />
        <Services />
        <Products />
        <Aboutus />
        <Blog />
        <Appointment />
        <Footer />
    </div>
  );
}

export default Home;