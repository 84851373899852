import React from 'react'
import './css/header.css'

function Header() {
  return (
    <div>
      <header className='header'>
        <a href='https://Hearingcare.com' className='logo'> SR Hearingcare</a>
        <nav className='navbar'>
            <a href='/home'>home</a>
            <a href='/home#about'>about</a>
            <a href='/home#services'>services</a>
            <a href='/home#Product'>Product</a>
            <a href="/home#blog">blog</a>
        </nav>
        <a href="/home#appointment" class="btn"> make appointment </a>
        <div id="menu-btn" class="fas fa-bars"></div>
      </header>

    </div>
  )
}

export default Header;
