import React from 'react'
import './css/footer.css'

function Footer() {
  return (
    <div>

    <section className="footer">

<div className="box-container">

    <div className="box">
        <h3>address</h3>
        <p>SR hearing care
        near busstand,<br />
        opp. pooja bakery, <br />
        ranebennuru -581115</p>
    </div>

    <div className="box">
        <h3>e-mail</h3>
        <a href="//gmail.com" className="link">sriratnahearingcare@gmail.com</a>
        <a href="//gmail.com" className="link">sriratnahearingcare@gmail.com</a>
    </div>
    
    <div className="box">
        <h3>call us</h3>
    
        <a href="tel:93434 30074"  className="link" >+91 93434 30074</a>
        <a href="tel:93434 30074"  className="link" >+91 93434 30074</a>
    </div>

    <div className="box">
        <h3>opening hours</h3>
        <p>Monday - Saturday  <br/>
            9:00 am - 7:00 pm   
        </p>
    </div>

    </div>

    <div className="credit">created by <span>Vin'S</span> | © 2023 SR Hearingcare</div>

    </section>

    </div>
  )
}

export default Footer;
