import React from 'react'
import './css/main.css'

function Main() {
  return (
    <div>
        
      <section className="home" id="home">
        <div class="content">
            <h3>we are here to hear you</h3>
            <p>Contact us today to schedule a consultation and start your path to improved hearing and overall well-being.</p>
            <a href="#appointment" class="btn">make appointment</a>
        </div>
      </section>

    </div>
  )
}

export default Main;
