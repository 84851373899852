import { useState } from 'react';
import React from 'react'
import './css/appointment.css'

function Appointment() {

  const [fname, setFname] = useState('')
  const [lname, setLname] = useState('')
  const [email, setEmail] = useState('')
  const [number, setNumber] = useState('')
  const [date, setDate] = useState('')
  
  async function appointment(event) {

    event.preventDefault()
    const response = await fetch('https://sr-backend-0e5a.onrender.com/api/patients',{
      method:'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        fname, 
        lname, 
        email, 
        number, 
        date,
      }),
    })

    const data = await response.json()
    console.log(data.status);

    if(data.status === "success"){
        window.alert("Appointment Successfull");
        window.location.reload();
    }
  }

  return (
    <div>
    <section className="contact" id="contact">

    <h1 className="heading" id="appointment">make appointment</h1>

      <form action="" onSubmit={appointment}>

          <span>your name :</span>
          <div className="inputBox">
              <input
                value={fname}
                onChange={(e) => setFname(e.target.value)}
                type="text" 
                placeholder="first name" 
                required="required" 
              />

              <input
                value={lname} 
                onChange={(e) => setLname(e.target.value)}
                type="text" 
                placeholder="last name" 
              />
          </div>

          <span>your email :</span>
          <input 
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email" 
            placeholder="enter your email" 
            className="box"
          />

          <span>your number :</span>
          <input
          value={number}
          onChange={(e) => setNumber(e.target.value)}
          type="number" 
          placeholder="enter your mobile number" 
          className="box" 
          required="required" 
          />

          <span>appointment date :</span>
          <input
          value={date}
          onChange={(e) => setDate(e.target.value)} 
          type="date" 
          className="box" 
          required="required" 
          />
                        
          <input 
          type="submit" 
          value="Make an appointment" 
          className="btn" 
          />

      </form>

    </section>
    </div>
  )
}

export default Appointment;
