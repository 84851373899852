import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/home';
import GHL from './pages/blogghl';
import Login from './pages/login';
import List from './pages/appointmentlist'
import './App.css';
import NIHL from './pages/blognihl';
import ARHL from './pages/blogarhl';
import AboutUs from './pages/aboutus';

function App() {
  return (
    <div>
      <Router>
        <Routes>
        <Route index element={<Home />} />
        <Route path='/home' element={<Home />} />
        <Route path='/login' element={<Login />} />
        <Route path='/appointmentlist' element={<List />} />
        <Route path='/Genetic_Hearing_Loss' element={<GHL />} />
        <Route path='/Noise-Induced_Hearing_Loss' element={<NIHL />} />
        <Route path='/Age-Related_Hearing_Loss' element={<ARHL />} />
        <Route path='/About_us' element={<AboutUs />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
